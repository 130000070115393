header {
  background-color: #ebecf0;
  
  &.border-bottom {
    border-color: #d2d2d2 !important;
  }
}

main {
  margin-bottom: 1em;
}

.logo {
  max-width: 256px;
}

.material-icons {
  display: inline-flex;
  vertical-align: middle;
  -webkit-user-select: none!important;
  -moz-user-select: none!important;
  -ms-user-select: none!important;
  user-select: none!important;
}

.bootstrap-icon {
  font-size: 20px;
  -webkit-user-select: none!important;
  -moz-user-select: none!important;
  -ms-user-select: none!important;
  user-select: none!important;
}

.user-info-loggedin.dropdown-toggle::after {
  margin-left: .455em;
}

.modal-banned .modal-content {
  border-color: #d41111;
}

.empty-state-message img {
  margin-bottom: 1.5em;  
}

.event-list-header {
  margin-bottom: calc(var(--bs-gutter-x)/ 2);
}

.event-item {
  cursor: pointer;

  padding: 0;
  margin-left: calc(var(--bs-gutter-x)/ 2);
  margin-right: calc(var(--bs-gutter-x)/ 2);
  max-width: calc(100% - var(--bs-gutter-x));

  >.card {
    transition: all 0.2s ease-in-out;
  }

  .event-logo {
    text-align: center;

    img {
      max-width: 128px;
      max-height: 128px;
      padding: 1em;
    }
  }

  .event-social {
    margin-right: .75em;
    display: inline-block;
    transition: all 0.2s ease-in-out;

    a {
      color: initial;
    }
  }

  .event-social-twitter:hover a {
    color: #1DA1F2 !important;
  }
}

.event-social-website:hover a, .event-social-wiki:hover a, a.material-icon-link:hover {
  color: #8c4aa8 !important;
}

.event-item .card:hover {
  box-shadow: 0 1px 8px #026395;
}

.material-icon-link {
  margin-left: 5px;
}

a.material-icon-link {
  color: inherit;
  text-decoration: none;
}

.callout {
  padding: 1.25rem;
  margin-bottom: 1.25rem;
  border: 1px solid #e9ecef;
  border-left-width: .25rem !important;
  border-radius: .25rem !important;
}

.callout.callout-warning {
  border: 1px solid #fdaa1c;
}

.callout.callout-success {
  border: 1px solid #73c322;
}

.callout.callout-danger {
  border: 1px solid #d41111;
}

.form-check .form-check-label, .form-check .form-check-input {
  cursor: pointer;
}

.user-avatar {
  display: inline-block;

  img {
    border: 1px solid white;
    margin-right: .4em;
    width: 32px;
    height: 32px;
    background-color: grey;
  }
}

.user-icon, .user-icon-bg {
  cursor: default;
}

.user-icon-mod, .user-icon-admin, .user-icon-poweruser {
  position: absolute;

  .user-icon {
    font-size: 16px;
    position: absolute;
    margin-top: -12px;
    margin-left: 19px;
    color: #73c322;
  }

  .user-icon-bg {
    font-size: 20px;
    position: absolute;
    color: white;
    margin-top: -14px;
    margin-left: 17px;
  }
}

.user-icon-admin .user-icon {
  color: #d41111;
}

.user-icon-poweruser .user-icon {
  color: #901cfd;
}

.user-info.dropdown .dropdown-menu {
  margin-top: 5px !important;
}

.event-post {
  margin-bottom: 0.75rem!important;

  .card-title a {
    color: #2489ce;
  }

  .event-report-post {
    cursor: pointer;
    display: inline-block;
    color: #8993a4;

    :hover {
      color: #d41111;
    }
  }
}

.event-badge-request {
  background-color: #901cfd;
}

.event-badge-offer {
  background-color: #1c6fd6;
}

#telegram-login-ConRoomExchangeBot {
  margin-top: 5px;
}

.footer {
  background-color: #253858;
  color: #ebecf0;

  a {
    color: #8cd1f7;
  }
}